// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ergasia-stin-enimeris-js": () => import("./../src/pages/ergasia-stin-enimeris.js" /* webpackChunkName: "component---src-pages-ergasia-stin-enimeris-js" */),
  "component---src-pages-imera-karieras-gia-beach-bar-js": () => import("./../src/pages/imera-karieras-gia-beach-bar.js" /* webpackChunkName: "component---src-pages-imera-karieras-gia-beach-bar-js" */),
  "component---src-pages-imera-karieras-gia-ergasia-se-beach-bar-js": () => import("./../src/pages/imera-karieras-gia-ergasia-se-beach-bar.js" /* webpackChunkName: "component---src-pages-imera-karieras-gia-ergasia-se-beach-bar-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-synergates-js": () => import("./../src/pages/synergates.js" /* webpackChunkName: "component---src-pages-synergates-js" */),
  "component---src-templates-job-template-js": () => import("./../src/templates/jobTemplate.js" /* webpackChunkName: "component---src-templates-job-template-js" */),
  "component---src-templates-our-job-template-js": () => import("./../src/templates/ourJobTemplate.js" /* webpackChunkName: "component---src-templates-our-job-template-js" */)
}

